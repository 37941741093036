export const profile = {
  customTitle: null,
  data: [
    {
      name: "",
      email: "",
      phone: null,
      jobTitle: null,
      city: null,
      website: null,
      photo: null,
      showFields: {
        jobTitle: true,
        phone: true,
        website: true,
        email: true,
        city: true,
        photo: true,
        upperCaseName: true,
      },
    },
  ],
};
export const Countries = [
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AD",
    COUNTRY_ALPHA3_CODE: "AND",
    COUNTRY_NUMERIC_CODE: 20,
    COUNTRY_NAME: "Andorra",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AE",
    COUNTRY_ALPHA3_CODE: "ARE",
    COUNTRY_NUMERIC_CODE: 784,
    COUNTRY_NAME: "United Arab Emirates",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AF",
    COUNTRY_ALPHA3_CODE: "AFG",
    COUNTRY_NUMERIC_CODE: 4,
    COUNTRY_NAME: "Afghanistan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AG",
    COUNTRY_ALPHA3_CODE: "ATG",
    COUNTRY_NUMERIC_CODE: 28,
    COUNTRY_NAME: "Antigua and Barbuda",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AI",
    COUNTRY_ALPHA3_CODE: "AIA",
    COUNTRY_NUMERIC_CODE: 660,
    COUNTRY_NAME: "Anguilla",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AL",
    COUNTRY_ALPHA3_CODE: "ALB",
    COUNTRY_NUMERIC_CODE: 8,
    COUNTRY_NAME: "Albania",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AM",
    COUNTRY_ALPHA3_CODE: "ARM",
    COUNTRY_NUMERIC_CODE: 51,
    COUNTRY_NAME: "Armenia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AO",
    COUNTRY_ALPHA3_CODE: "AGO",
    COUNTRY_NUMERIC_CODE: 24,
    COUNTRY_NAME: "Angola",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AQ",
    COUNTRY_ALPHA3_CODE: "ATA",
    COUNTRY_NUMERIC_CODE: 10,
    COUNTRY_NAME: "Antarctica",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AR",
    COUNTRY_ALPHA3_CODE: "ARG",
    COUNTRY_NUMERIC_CODE: 32,
    COUNTRY_NAME: "Argentina",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AS",
    COUNTRY_ALPHA3_CODE: "ASM",
    COUNTRY_NUMERIC_CODE: 16,
    COUNTRY_NAME: "American Samoa",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AT",
    COUNTRY_ALPHA3_CODE: "AUT",
    COUNTRY_NUMERIC_CODE: 40,
    COUNTRY_NAME: "Austria",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AU",
    COUNTRY_ALPHA3_CODE: "AUS",
    COUNTRY_NUMERIC_CODE: 36,
    COUNTRY_NAME: "Australia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AW",
    COUNTRY_ALPHA3_CODE: "ABW",
    COUNTRY_NUMERIC_CODE: 533,
    COUNTRY_NAME: "Aruba",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AX",
    COUNTRY_ALPHA3_CODE: "ALA",
    COUNTRY_NUMERIC_CODE: 248,
    COUNTRY_NAME: "Aland Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "AZ",
    COUNTRY_ALPHA3_CODE: "AZE",
    COUNTRY_NUMERIC_CODE: 31,
    COUNTRY_NAME: "Azerbaijan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BA",
    COUNTRY_ALPHA3_CODE: "BIH",
    COUNTRY_NUMERIC_CODE: 70,
    COUNTRY_NAME: "Bosnia and Herzegovina",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BB",
    COUNTRY_ALPHA3_CODE: "BRB",
    COUNTRY_NUMERIC_CODE: 52,
    COUNTRY_NAME: "Barbados",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BD",
    COUNTRY_ALPHA3_CODE: "BGD",
    COUNTRY_NUMERIC_CODE: 50,
    COUNTRY_NAME: "Bangladesh",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BE",
    COUNTRY_ALPHA3_CODE: "BEL",
    COUNTRY_NUMERIC_CODE: 56,
    COUNTRY_NAME: "Belgium",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BF",
    COUNTRY_ALPHA3_CODE: "BFA",
    COUNTRY_NUMERIC_CODE: 854,
    COUNTRY_NAME: "Burkina Faso",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BG",
    COUNTRY_ALPHA3_CODE: "BGR",
    COUNTRY_NUMERIC_CODE: 100,
    COUNTRY_NAME: "Bulgaria",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BH",
    COUNTRY_ALPHA3_CODE: "BHR",
    COUNTRY_NUMERIC_CODE: 48,
    COUNTRY_NAME: "Bahrain",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BI",
    COUNTRY_ALPHA3_CODE: "BDI",
    COUNTRY_NUMERIC_CODE: 108,
    COUNTRY_NAME: "Burundi",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BJ",
    COUNTRY_ALPHA3_CODE: "BEN",
    COUNTRY_NUMERIC_CODE: 204,
    COUNTRY_NAME: "Benin",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BL",
    COUNTRY_ALPHA3_CODE: "BLM",
    COUNTRY_NUMERIC_CODE: 652,
    COUNTRY_NAME: "Saint Barthelemy",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BM",
    COUNTRY_ALPHA3_CODE: "BMU",
    COUNTRY_NUMERIC_CODE: 60,
    COUNTRY_NAME: "Bermuda",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BN",
    COUNTRY_ALPHA3_CODE: "BRN",
    COUNTRY_NUMERIC_CODE: 96,
    COUNTRY_NAME: "Brunei Darussalam",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BO",
    COUNTRY_ALPHA3_CODE: "BOL",
    COUNTRY_NUMERIC_CODE: 68,
    COUNTRY_NAME: "Bolivia (Plurinational State of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BQ",
    COUNTRY_ALPHA3_CODE: "BES",
    COUNTRY_NUMERIC_CODE: 535,
    COUNTRY_NAME: "Bonaire, Sint Eustatius and Saba",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BR",
    COUNTRY_ALPHA3_CODE: "BRA",
    COUNTRY_NUMERIC_CODE: 76,
    COUNTRY_NAME: "Brazil",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BS",
    COUNTRY_ALPHA3_CODE: "BHS",
    COUNTRY_NUMERIC_CODE: 44,
    COUNTRY_NAME: "Bahamas",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BT",
    COUNTRY_ALPHA3_CODE: "BTN",
    COUNTRY_NUMERIC_CODE: 64,
    COUNTRY_NAME: "Bhutan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BV",
    COUNTRY_ALPHA3_CODE: "BVT",
    COUNTRY_NUMERIC_CODE: 74,
    COUNTRY_NAME: "Bouvet Island",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BW",
    COUNTRY_ALPHA3_CODE: "BWA",
    COUNTRY_NUMERIC_CODE: 72,
    COUNTRY_NAME: "Botswana",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BY",
    COUNTRY_ALPHA3_CODE: "BLR",
    COUNTRY_NUMERIC_CODE: 112,
    COUNTRY_NAME: "Belarus",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "BZ",
    COUNTRY_ALPHA3_CODE: "BLZ",
    COUNTRY_NUMERIC_CODE: 84,
    COUNTRY_NAME: "Belize",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CA",
    COUNTRY_ALPHA3_CODE: "CAN",
    COUNTRY_NUMERIC_CODE: 124,
    COUNTRY_NAME: "Canada",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CC",
    COUNTRY_ALPHA3_CODE: "CCK",
    COUNTRY_NUMERIC_CODE: 166,
    COUNTRY_NAME: "Cocos (Keeling) Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CD",
    COUNTRY_ALPHA3_CODE: "COD",
    COUNTRY_NUMERIC_CODE: 180,
    COUNTRY_NAME: "Congo (Democratic Republic of the)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CF",
    COUNTRY_ALPHA3_CODE: "CAF",
    COUNTRY_NUMERIC_CODE: 140,
    COUNTRY_NAME: "Central African Republic",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CG",
    COUNTRY_ALPHA3_CODE: "COG",
    COUNTRY_NUMERIC_CODE: 178,
    COUNTRY_NAME: "Congo",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CH",
    COUNTRY_ALPHA3_CODE: "CHE",
    COUNTRY_NUMERIC_CODE: 756,
    COUNTRY_NAME: "Switzerland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CI",
    COUNTRY_ALPHA3_CODE: "CIV",
    COUNTRY_NUMERIC_CODE: 384,
    COUNTRY_NAME: "Cote d'Ivoire",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CK",
    COUNTRY_ALPHA3_CODE: "COK",
    COUNTRY_NUMERIC_CODE: 184,
    COUNTRY_NAME: "Cook Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CL",
    COUNTRY_ALPHA3_CODE: "CHL",
    COUNTRY_NUMERIC_CODE: 152,
    COUNTRY_NAME: "Chile",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CM",
    COUNTRY_ALPHA3_CODE: "CMR",
    COUNTRY_NUMERIC_CODE: 120,
    COUNTRY_NAME: "Cameroon",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CN",
    COUNTRY_ALPHA3_CODE: "CHN",
    COUNTRY_NUMERIC_CODE: 156,
    COUNTRY_NAME: "China",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CO",
    COUNTRY_ALPHA3_CODE: "COL",
    COUNTRY_NUMERIC_CODE: 170,
    COUNTRY_NAME: "Colombia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CR",
    COUNTRY_ALPHA3_CODE: "CRI",
    COUNTRY_NUMERIC_CODE: 188,
    COUNTRY_NAME: "Costa Rica",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CU",
    COUNTRY_ALPHA3_CODE: "CUB",
    COUNTRY_NUMERIC_CODE: 192,
    COUNTRY_NAME: "Cuba",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CV",
    COUNTRY_ALPHA3_CODE: "CPV",
    COUNTRY_NUMERIC_CODE: 132,
    COUNTRY_NAME: "Cabo Verde",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CW",
    COUNTRY_ALPHA3_CODE: "CUW",
    COUNTRY_NUMERIC_CODE: 531,
    COUNTRY_NAME: "Curaçao",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CX",
    COUNTRY_ALPHA3_CODE: "CXR",
    COUNTRY_NUMERIC_CODE: 162,
    COUNTRY_NAME: "Christmas Island",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CY",
    COUNTRY_ALPHA3_CODE: "CYP",
    COUNTRY_NUMERIC_CODE: 196,
    COUNTRY_NAME: "Cyprus",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "CZ",
    COUNTRY_ALPHA3_CODE: "CZE",
    COUNTRY_NUMERIC_CODE: 203,
    COUNTRY_NAME: "Czechia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DE",
    COUNTRY_ALPHA3_CODE: "DEU",
    COUNTRY_NUMERIC_CODE: 276,
    COUNTRY_NAME: "Germany",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DJ",
    COUNTRY_ALPHA3_CODE: "DJI",
    COUNTRY_NUMERIC_CODE: 262,
    COUNTRY_NAME: "Djibouti",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DK",
    COUNTRY_ALPHA3_CODE: "DNK",
    COUNTRY_NUMERIC_CODE: 208,
    COUNTRY_NAME: "Denmark",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DM",
    COUNTRY_ALPHA3_CODE: "DMA",
    COUNTRY_NUMERIC_CODE: 212,
    COUNTRY_NAME: "Dominica",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DO",
    COUNTRY_ALPHA3_CODE: "DOM",
    COUNTRY_NUMERIC_CODE: 214,
    COUNTRY_NAME: "Dominican Republic",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "DZ",
    COUNTRY_ALPHA3_CODE: "DZA",
    COUNTRY_NUMERIC_CODE: 12,
    COUNTRY_NAME: "Algeria",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "EC",
    COUNTRY_ALPHA3_CODE: "ECU",
    COUNTRY_NUMERIC_CODE: 218,
    COUNTRY_NAME: "Ecuador",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "EE",
    COUNTRY_ALPHA3_CODE: "EST",
    COUNTRY_NUMERIC_CODE: 233,
    COUNTRY_NAME: "Estonia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "EG",
    COUNTRY_ALPHA3_CODE: "EGY",
    COUNTRY_NUMERIC_CODE: 818,
    COUNTRY_NAME: "Egypt",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "EH",
    COUNTRY_ALPHA3_CODE: "ESH",
    COUNTRY_NUMERIC_CODE: 732,
    COUNTRY_NAME: "Western Sahara",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ER",
    COUNTRY_ALPHA3_CODE: "ERI",
    COUNTRY_NUMERIC_CODE: 232,
    COUNTRY_NAME: "Eritrea",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ES",
    COUNTRY_ALPHA3_CODE: "ESP",
    COUNTRY_NUMERIC_CODE: 724,
    COUNTRY_NAME: "Spain",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ET",
    COUNTRY_ALPHA3_CODE: "ETH",
    COUNTRY_NUMERIC_CODE: 231,
    COUNTRY_NAME: "Ethiopia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FI",
    COUNTRY_ALPHA3_CODE: "FIN",
    COUNTRY_NUMERIC_CODE: 246,
    COUNTRY_NAME: "Finland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FJ",
    COUNTRY_ALPHA3_CODE: "FJI",
    COUNTRY_NUMERIC_CODE: 242,
    COUNTRY_NAME: "Fiji",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FK",
    COUNTRY_ALPHA3_CODE: "FLK",
    COUNTRY_NUMERIC_CODE: 238,
    COUNTRY_NAME: "Falkland Islands (Malvinas)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FM",
    COUNTRY_ALPHA3_CODE: "FSM",
    COUNTRY_NUMERIC_CODE: 583,
    COUNTRY_NAME: "Micronesia (Federated States of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FO",
    COUNTRY_ALPHA3_CODE: "FRO",
    COUNTRY_NUMERIC_CODE: 234,
    COUNTRY_NAME: "Faroe Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "FR",
    COUNTRY_ALPHA3_CODE: "FRA",
    COUNTRY_NUMERIC_CODE: 250,
    COUNTRY_NAME: "France",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GA",
    COUNTRY_ALPHA3_CODE: "GAB",
    COUNTRY_NUMERIC_CODE: 266,
    COUNTRY_NAME: "Gabon",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GB",
    COUNTRY_ALPHA3_CODE: "GBR",
    COUNTRY_NUMERIC_CODE: 826,
    COUNTRY_NAME: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GD",
    COUNTRY_ALPHA3_CODE: "GRD",
    COUNTRY_NUMERIC_CODE: 308,
    COUNTRY_NAME: "Grenada",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GE",
    COUNTRY_ALPHA3_CODE: "GEO",
    COUNTRY_NUMERIC_CODE: 268,
    COUNTRY_NAME: "Georgia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GF",
    COUNTRY_ALPHA3_CODE: "GUF",
    COUNTRY_NUMERIC_CODE: 254,
    COUNTRY_NAME: "French Guiana",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GG",
    COUNTRY_ALPHA3_CODE: "GGY",
    COUNTRY_NUMERIC_CODE: 831,
    COUNTRY_NAME: "Guernsey",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GH",
    COUNTRY_ALPHA3_CODE: "GHA",
    COUNTRY_NUMERIC_CODE: 288,
    COUNTRY_NAME: "Ghana",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GI",
    COUNTRY_ALPHA3_CODE: "GIB",
    COUNTRY_NUMERIC_CODE: 292,
    COUNTRY_NAME: "Gibraltar",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GL",
    COUNTRY_ALPHA3_CODE: "GRL",
    COUNTRY_NUMERIC_CODE: 304,
    COUNTRY_NAME: "Greenland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GM",
    COUNTRY_ALPHA3_CODE: "GMB",
    COUNTRY_NUMERIC_CODE: 270,
    COUNTRY_NAME: "Gambia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GN",
    COUNTRY_ALPHA3_CODE: "GIN",
    COUNTRY_NUMERIC_CODE: 324,
    COUNTRY_NAME: "Guinea",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GP",
    COUNTRY_ALPHA3_CODE: "GLP",
    COUNTRY_NUMERIC_CODE: 312,
    COUNTRY_NAME: "Guadeloupe",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GQ",
    COUNTRY_ALPHA3_CODE: "GNQ",
    COUNTRY_NUMERIC_CODE: 226,
    COUNTRY_NAME: "Equatorial Guinea",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GR",
    COUNTRY_ALPHA3_CODE: "GRC",
    COUNTRY_NUMERIC_CODE: 300,
    COUNTRY_NAME: "Greece",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GS",
    COUNTRY_ALPHA3_CODE: "SGS",
    COUNTRY_NUMERIC_CODE: 239,
    COUNTRY_NAME: "South Georgia and the South Sandwich Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GT",
    COUNTRY_ALPHA3_CODE: "GTM",
    COUNTRY_NUMERIC_CODE: 320,
    COUNTRY_NAME: "Guatemala",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GU",
    COUNTRY_ALPHA3_CODE: "GUM",
    COUNTRY_NUMERIC_CODE: 316,
    COUNTRY_NAME: "Guam",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GW",
    COUNTRY_ALPHA3_CODE: "GNB",
    COUNTRY_NUMERIC_CODE: 624,
    COUNTRY_NAME: "Guinea-Bissau",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "GY",
    COUNTRY_ALPHA3_CODE: "GUY",
    COUNTRY_NUMERIC_CODE: 328,
    COUNTRY_NAME: "Guyana",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HK",
    COUNTRY_ALPHA3_CODE: "HKG",
    COUNTRY_NUMERIC_CODE: 344,
    COUNTRY_NAME: "Hong Kong",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HM",
    COUNTRY_ALPHA3_CODE: "HMD",
    COUNTRY_NUMERIC_CODE: 334,
    COUNTRY_NAME: "Heard Island and McDonald Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HN",
    COUNTRY_ALPHA3_CODE: "HND",
    COUNTRY_NUMERIC_CODE: 340,
    COUNTRY_NAME: "Honduras",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HR",
    COUNTRY_ALPHA3_CODE: "HRV",
    COUNTRY_NUMERIC_CODE: 191,
    COUNTRY_NAME: "Croatia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HT",
    COUNTRY_ALPHA3_CODE: "HTI",
    COUNTRY_NUMERIC_CODE: 332,
    COUNTRY_NAME: "Haiti",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "HU",
    COUNTRY_ALPHA3_CODE: "HUN",
    COUNTRY_NUMERIC_CODE: 348,
    COUNTRY_NAME: "Hungary",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ID",
    COUNTRY_ALPHA3_CODE: "IDN",
    COUNTRY_NUMERIC_CODE: 360,
    COUNTRY_NAME: "Indonesia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IE",
    COUNTRY_ALPHA3_CODE: "IRL",
    COUNTRY_NUMERIC_CODE: 372,
    COUNTRY_NAME: "Ireland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IL",
    COUNTRY_ALPHA3_CODE: "ISR",
    COUNTRY_NUMERIC_CODE: 376,
    COUNTRY_NAME: "Israel",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IM",
    COUNTRY_ALPHA3_CODE: "IMN",
    COUNTRY_NUMERIC_CODE: 833,
    COUNTRY_NAME: "Isle of Man",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IN",
    COUNTRY_ALPHA3_CODE: "IND",
    COUNTRY_NUMERIC_CODE: 356,
    COUNTRY_NAME: "India",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IO",
    COUNTRY_ALPHA3_CODE: "IOT",
    COUNTRY_NUMERIC_CODE: 86,
    COUNTRY_NAME: "British Indian Ocean Territory",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IQ",
    COUNTRY_ALPHA3_CODE: "IRQ",
    COUNTRY_NUMERIC_CODE: 368,
    COUNTRY_NAME: "Iraq",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IR",
    COUNTRY_ALPHA3_CODE: "IRN",
    COUNTRY_NUMERIC_CODE: 364,
    COUNTRY_NAME: "Iran (Islamic Republic of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IS",
    COUNTRY_ALPHA3_CODE: "ISL",
    COUNTRY_NUMERIC_CODE: 352,
    COUNTRY_NAME: "Iceland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "IT",
    COUNTRY_ALPHA3_CODE: "ITA",
    COUNTRY_NUMERIC_CODE: 380,
    COUNTRY_NAME: "Italy",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "JE",
    COUNTRY_ALPHA3_CODE: "JEY",
    COUNTRY_NUMERIC_CODE: 832,
    COUNTRY_NAME: "Jersey",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "JM",
    COUNTRY_ALPHA3_CODE: "JAM",
    COUNTRY_NUMERIC_CODE: 388,
    COUNTRY_NAME: "Jamaica",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "JO",
    COUNTRY_ALPHA3_CODE: "JOR",
    COUNTRY_NUMERIC_CODE: 400,
    COUNTRY_NAME: "Jordan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "JP",
    COUNTRY_ALPHA3_CODE: "JPN",
    COUNTRY_NUMERIC_CODE: 392,
    COUNTRY_NAME: "Japan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KE",
    COUNTRY_ALPHA3_CODE: "KEN",
    COUNTRY_NUMERIC_CODE: 404,
    COUNTRY_NAME: "Kenya",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KG",
    COUNTRY_ALPHA3_CODE: "KGZ",
    COUNTRY_NUMERIC_CODE: 417,
    COUNTRY_NAME: "Kyrgyzstan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KH",
    COUNTRY_ALPHA3_CODE: "KHM",
    COUNTRY_NUMERIC_CODE: 116,
    COUNTRY_NAME: "Cambodia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KI",
    COUNTRY_ALPHA3_CODE: "KIR",
    COUNTRY_NUMERIC_CODE: 296,
    COUNTRY_NAME: "Kiribati",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KM",
    COUNTRY_ALPHA3_CODE: "COM",
    COUNTRY_NUMERIC_CODE: 174,
    COUNTRY_NAME: "Comoros",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KN",
    COUNTRY_ALPHA3_CODE: "KNA",
    COUNTRY_NUMERIC_CODE: 659,
    COUNTRY_NAME: "Saint Kitts and Nevis",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KP",
    COUNTRY_ALPHA3_CODE: "PRK",
    COUNTRY_NUMERIC_CODE: 408,
    COUNTRY_NAME: "Korea (Democratic People's Republic of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KR",
    COUNTRY_ALPHA3_CODE: "KOR",
    COUNTRY_NUMERIC_CODE: 410,
    COUNTRY_NAME: "Korea (Republic of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KW",
    COUNTRY_ALPHA3_CODE: "KWT",
    COUNTRY_NUMERIC_CODE: 414,
    COUNTRY_NAME: "Kuwait",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KY",
    COUNTRY_ALPHA3_CODE: "CYM",
    COUNTRY_NUMERIC_CODE: 136,
    COUNTRY_NAME: "Cayman Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "KZ",
    COUNTRY_ALPHA3_CODE: "KAZ",
    COUNTRY_NUMERIC_CODE: 398,
    COUNTRY_NAME: "Kazakhstan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LA",
    COUNTRY_ALPHA3_CODE: "LAO",
    COUNTRY_NUMERIC_CODE: 418,
    COUNTRY_NAME: "Lao People's Democratic Republic",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LB",
    COUNTRY_ALPHA3_CODE: "LBN",
    COUNTRY_NUMERIC_CODE: 422,
    COUNTRY_NAME: "Lebanon",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LC",
    COUNTRY_ALPHA3_CODE: "LCA",
    COUNTRY_NUMERIC_CODE: 662,
    COUNTRY_NAME: "Saint Lucia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LI",
    COUNTRY_ALPHA3_CODE: "LIE",
    COUNTRY_NUMERIC_CODE: 438,
    COUNTRY_NAME: "Liechtenstein",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LK",
    COUNTRY_ALPHA3_CODE: "LKA",
    COUNTRY_NUMERIC_CODE: 144,
    COUNTRY_NAME: "Sri Lanka",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LR",
    COUNTRY_ALPHA3_CODE: "LBR",
    COUNTRY_NUMERIC_CODE: 430,
    COUNTRY_NAME: "Liberia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LS",
    COUNTRY_ALPHA3_CODE: "LSO",
    COUNTRY_NUMERIC_CODE: 426,
    COUNTRY_NAME: "Lesotho",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LT",
    COUNTRY_ALPHA3_CODE: "LTU",
    COUNTRY_NUMERIC_CODE: 440,
    COUNTRY_NAME: "Lithuania",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LU",
    COUNTRY_ALPHA3_CODE: "LUX",
    COUNTRY_NUMERIC_CODE: 442,
    COUNTRY_NAME: "Luxembourg",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LV",
    COUNTRY_ALPHA3_CODE: "LVA",
    COUNTRY_NUMERIC_CODE: 428,
    COUNTRY_NAME: "Latvia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "LY",
    COUNTRY_ALPHA3_CODE: "LBY",
    COUNTRY_NUMERIC_CODE: 434,
    COUNTRY_NAME: "Libya",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MA",
    COUNTRY_ALPHA3_CODE: "MAR",
    COUNTRY_NUMERIC_CODE: 504,
    COUNTRY_NAME: "Morocco",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MC",
    COUNTRY_ALPHA3_CODE: "MCO",
    COUNTRY_NUMERIC_CODE: 492,
    COUNTRY_NAME: "Monaco",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MD",
    COUNTRY_ALPHA3_CODE: "MDA",
    COUNTRY_NUMERIC_CODE: 498,
    COUNTRY_NAME: "Moldova (Republic of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ME",
    COUNTRY_ALPHA3_CODE: "MNE",
    COUNTRY_NUMERIC_CODE: 499,
    COUNTRY_NAME: "Montenegro",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MF",
    COUNTRY_ALPHA3_CODE: "MAF",
    COUNTRY_NUMERIC_CODE: 663,
    COUNTRY_NAME: "Saint Martin (French Part)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MG",
    COUNTRY_ALPHA3_CODE: "MDG",
    COUNTRY_NUMERIC_CODE: 450,
    COUNTRY_NAME: "Madagascar",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MH",
    COUNTRY_ALPHA3_CODE: "MHL",
    COUNTRY_NUMERIC_CODE: 584,
    COUNTRY_NAME: "Marshall Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MK",
    COUNTRY_ALPHA3_CODE: "MKD",
    COUNTRY_NUMERIC_CODE: 807,
    COUNTRY_NAME: "North Macedonia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ML",
    COUNTRY_ALPHA3_CODE: "MLI",
    COUNTRY_NUMERIC_CODE: 466,
    COUNTRY_NAME: "Mali",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MM",
    COUNTRY_ALPHA3_CODE: "MMR",
    COUNTRY_NUMERIC_CODE: 104,
    COUNTRY_NAME: "Myanmar",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MN",
    COUNTRY_ALPHA3_CODE: "MNG",
    COUNTRY_NUMERIC_CODE: 496,
    COUNTRY_NAME: "Mongolia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MO",
    COUNTRY_ALPHA3_CODE: "MAC",
    COUNTRY_NUMERIC_CODE: 446,
    COUNTRY_NAME: "Macao",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MP",
    COUNTRY_ALPHA3_CODE: "MNP",
    COUNTRY_NUMERIC_CODE: 580,
    COUNTRY_NAME: "Northern Mariana Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MQ",
    COUNTRY_ALPHA3_CODE: "MTQ",
    COUNTRY_NUMERIC_CODE: 474,
    COUNTRY_NAME: "Martinique",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MR",
    COUNTRY_ALPHA3_CODE: "MRT",
    COUNTRY_NUMERIC_CODE: 478,
    COUNTRY_NAME: "Mauritania",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MS",
    COUNTRY_ALPHA3_CODE: "MSR",
    COUNTRY_NUMERIC_CODE: 500,
    COUNTRY_NAME: "Montserrat",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MT",
    COUNTRY_ALPHA3_CODE: "MLT",
    COUNTRY_NUMERIC_CODE: 470,
    COUNTRY_NAME: "Malta",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MU",
    COUNTRY_ALPHA3_CODE: "MUS",
    COUNTRY_NUMERIC_CODE: 480,
    COUNTRY_NAME: "Mauritius",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MV",
    COUNTRY_ALPHA3_CODE: "MDV",
    COUNTRY_NUMERIC_CODE: 462,
    COUNTRY_NAME: "Maldives",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MW",
    COUNTRY_ALPHA3_CODE: "MWI",
    COUNTRY_NUMERIC_CODE: 454,
    COUNTRY_NAME: "Malawi",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MX",
    COUNTRY_ALPHA3_CODE: "MEX",
    COUNTRY_NUMERIC_CODE: 484,
    COUNTRY_NAME: "Mexico",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MY",
    COUNTRY_ALPHA3_CODE: "MYS",
    COUNTRY_NUMERIC_CODE: 458,
    COUNTRY_NAME: "Malaysia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "MZ",
    COUNTRY_ALPHA3_CODE: "MOZ",
    COUNTRY_NUMERIC_CODE: 508,
    COUNTRY_NAME: "Mozambique",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NA",
    COUNTRY_ALPHA3_CODE: "NAM",
    COUNTRY_NUMERIC_CODE: 516,
    COUNTRY_NAME: "Namibia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NC",
    COUNTRY_ALPHA3_CODE: "NCL",
    COUNTRY_NUMERIC_CODE: 540,
    COUNTRY_NAME: "New Caledonia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NE",
    COUNTRY_ALPHA3_CODE: "NER",
    COUNTRY_NUMERIC_CODE: 562,
    COUNTRY_NAME: "Niger",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NF",
    COUNTRY_ALPHA3_CODE: "NFK",
    COUNTRY_NUMERIC_CODE: 574,
    COUNTRY_NAME: "Norfolk Island",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NG",
    COUNTRY_ALPHA3_CODE: "NGA",
    COUNTRY_NUMERIC_CODE: 566,
    COUNTRY_NAME: "Nigeria",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NI",
    COUNTRY_ALPHA3_CODE: "NIC",
    COUNTRY_NUMERIC_CODE: 558,
    COUNTRY_NAME: "Nicaragua",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NL",
    COUNTRY_ALPHA3_CODE: "NLD",
    COUNTRY_NUMERIC_CODE: 528,
    COUNTRY_NAME: "Netherlands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NO",
    COUNTRY_ALPHA3_CODE: "NOR",
    COUNTRY_NUMERIC_CODE: 578,
    COUNTRY_NAME: "Norway",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NP",
    COUNTRY_ALPHA3_CODE: "NPL",
    COUNTRY_NUMERIC_CODE: 524,
    COUNTRY_NAME: "Nepal",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NR",
    COUNTRY_ALPHA3_CODE: "NRU",
    COUNTRY_NUMERIC_CODE: 520,
    COUNTRY_NAME: "Nauru",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NU",
    COUNTRY_ALPHA3_CODE: "NIU",
    COUNTRY_NUMERIC_CODE: 570,
    COUNTRY_NAME: "Niue",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "NZ",
    COUNTRY_ALPHA3_CODE: "NZL",
    COUNTRY_NUMERIC_CODE: 554,
    COUNTRY_NAME: "New Zealand",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "OM",
    COUNTRY_ALPHA3_CODE: "OMN",
    COUNTRY_NUMERIC_CODE: 512,
    COUNTRY_NAME: "Oman",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PA",
    COUNTRY_ALPHA3_CODE: "PAN",
    COUNTRY_NUMERIC_CODE: 591,
    COUNTRY_NAME: "Panama",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PE",
    COUNTRY_ALPHA3_CODE: "PER",
    COUNTRY_NUMERIC_CODE: 604,
    COUNTRY_NAME: "Peru",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PF",
    COUNTRY_ALPHA3_CODE: "PYF",
    COUNTRY_NUMERIC_CODE: 258,
    COUNTRY_NAME: "French Polynesia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PG",
    COUNTRY_ALPHA3_CODE: "PNG",
    COUNTRY_NUMERIC_CODE: 598,
    COUNTRY_NAME: "Papua New Guinea",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PH",
    COUNTRY_ALPHA3_CODE: "PHL",
    COUNTRY_NUMERIC_CODE: 608,
    COUNTRY_NAME: "Philippines",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PK",
    COUNTRY_ALPHA3_CODE: "PAK",
    COUNTRY_NUMERIC_CODE: 586,
    COUNTRY_NAME: "Pakistan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PL",
    COUNTRY_ALPHA3_CODE: "POL",
    COUNTRY_NUMERIC_CODE: 616,
    COUNTRY_NAME: "Poland",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PM",
    COUNTRY_ALPHA3_CODE: "SPM",
    COUNTRY_NUMERIC_CODE: 666,
    COUNTRY_NAME: "Saint Pierre and Miquelon",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PN",
    COUNTRY_ALPHA3_CODE: "PCN",
    COUNTRY_NUMERIC_CODE: 612,
    COUNTRY_NAME: "Pitcairn",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PR",
    COUNTRY_ALPHA3_CODE: "PRI",
    COUNTRY_NUMERIC_CODE: 630,
    COUNTRY_NAME: "Puerto Rico",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PS",
    COUNTRY_ALPHA3_CODE: "PSE",
    COUNTRY_NUMERIC_CODE: 275,
    COUNTRY_NAME: "Palestine, State of",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PT",
    COUNTRY_ALPHA3_CODE: "PRT",
    COUNTRY_NUMERIC_CODE: 620,
    COUNTRY_NAME: "Portugal",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PW",
    COUNTRY_ALPHA3_CODE: "PLW",
    COUNTRY_NUMERIC_CODE: 585,
    COUNTRY_NAME: "Palau",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "PY",
    COUNTRY_ALPHA3_CODE: "PRY",
    COUNTRY_NUMERIC_CODE: 600,
    COUNTRY_NAME: "Paraguay",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "QA",
    COUNTRY_ALPHA3_CODE: "QAT",
    COUNTRY_NUMERIC_CODE: 634,
    COUNTRY_NAME: "Qatar",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "RE",
    COUNTRY_ALPHA3_CODE: "REU",
    COUNTRY_NUMERIC_CODE: 638,
    COUNTRY_NAME: "Reunion",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "RO",
    COUNTRY_ALPHA3_CODE: "ROU",
    COUNTRY_NUMERIC_CODE: 642,
    COUNTRY_NAME: "Romania",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "RS",
    COUNTRY_ALPHA3_CODE: "SRB",
    COUNTRY_NUMERIC_CODE: 688,
    COUNTRY_NAME: "Serbia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "RU",
    COUNTRY_ALPHA3_CODE: "RUS",
    COUNTRY_NUMERIC_CODE: 643,
    COUNTRY_NAME: "Russian Federation",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "RW",
    COUNTRY_ALPHA3_CODE: "RWA",
    COUNTRY_NUMERIC_CODE: 646,
    COUNTRY_NAME: "Rwanda",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SA",
    COUNTRY_ALPHA3_CODE: "SAU",
    COUNTRY_NUMERIC_CODE: 682,
    COUNTRY_NAME: "Saudi Arabia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SB",
    COUNTRY_ALPHA3_CODE: "SLB",
    COUNTRY_NUMERIC_CODE: 90,
    COUNTRY_NAME: "Solomon Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SC",
    COUNTRY_ALPHA3_CODE: "SYC",
    COUNTRY_NUMERIC_CODE: 690,
    COUNTRY_NAME: "Seychelles",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SD",
    COUNTRY_ALPHA3_CODE: "SDN",
    COUNTRY_NUMERIC_CODE: 729,
    COUNTRY_NAME: "Sudan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SE",
    COUNTRY_ALPHA3_CODE: "SWE",
    COUNTRY_NUMERIC_CODE: 752,
    COUNTRY_NAME: "Sweden",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SG",
    COUNTRY_ALPHA3_CODE: "SGP",
    COUNTRY_NUMERIC_CODE: 702,
    COUNTRY_NAME: "Singapore",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SH",
    COUNTRY_ALPHA3_CODE: "SHN",
    COUNTRY_NUMERIC_CODE: 654,
    COUNTRY_NAME: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SI",
    COUNTRY_ALPHA3_CODE: "SVN",
    COUNTRY_NUMERIC_CODE: 705,
    COUNTRY_NAME: "Slovenia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SJ",
    COUNTRY_ALPHA3_CODE: "SJM",
    COUNTRY_NUMERIC_CODE: 744,
    COUNTRY_NAME: "Svalbard and Jan Mayen",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SK",
    COUNTRY_ALPHA3_CODE: "SVK",
    COUNTRY_NUMERIC_CODE: 703,
    COUNTRY_NAME: "Slovakia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SL",
    COUNTRY_ALPHA3_CODE: "SLE",
    COUNTRY_NUMERIC_CODE: 694,
    COUNTRY_NAME: "Sierra Leone",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SM",
    COUNTRY_ALPHA3_CODE: "SMR",
    COUNTRY_NUMERIC_CODE: 674,
    COUNTRY_NAME: "San Marino",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SN",
    COUNTRY_ALPHA3_CODE: "SEN",
    COUNTRY_NUMERIC_CODE: 686,
    COUNTRY_NAME: "Senegal",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SO",
    COUNTRY_ALPHA3_CODE: "SOM",
    COUNTRY_NUMERIC_CODE: 706,
    COUNTRY_NAME: "Somalia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SR",
    COUNTRY_ALPHA3_CODE: "SUR",
    COUNTRY_NUMERIC_CODE: 740,
    COUNTRY_NAME: "Suriname",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SS",
    COUNTRY_ALPHA3_CODE: "SSD",
    COUNTRY_NUMERIC_CODE: 728,
    COUNTRY_NAME: "South Sudan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ST",
    COUNTRY_ALPHA3_CODE: "STP",
    COUNTRY_NUMERIC_CODE: 678,
    COUNTRY_NAME: "Sao Tome and Principe",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SV",
    COUNTRY_ALPHA3_CODE: "SLV",
    COUNTRY_NUMERIC_CODE: 222,
    COUNTRY_NAME: "El Salvador",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SX",
    COUNTRY_ALPHA3_CODE: "SXM",
    COUNTRY_NUMERIC_CODE: 534,
    COUNTRY_NAME: "Sint Maarten (Dutch Part)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SY",
    COUNTRY_ALPHA3_CODE: "SYR",
    COUNTRY_NUMERIC_CODE: 760,
    COUNTRY_NAME: "Syrian Arab Republic",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "SZ",
    COUNTRY_ALPHA3_CODE: "SWZ",
    COUNTRY_NUMERIC_CODE: 748,
    COUNTRY_NAME: "Eswatini",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TC",
    COUNTRY_ALPHA3_CODE: "TCA",
    COUNTRY_NUMERIC_CODE: 796,
    COUNTRY_NAME: "Turks and Caicos Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TD",
    COUNTRY_ALPHA3_CODE: "TCD",
    COUNTRY_NUMERIC_CODE: 148,
    COUNTRY_NAME: "Chad",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TF",
    COUNTRY_ALPHA3_CODE: "ATF",
    COUNTRY_NUMERIC_CODE: 260,
    COUNTRY_NAME: "French Southern Territories",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TG",
    COUNTRY_ALPHA3_CODE: "TGO",
    COUNTRY_NUMERIC_CODE: 768,
    COUNTRY_NAME: "Togo",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TH",
    COUNTRY_ALPHA3_CODE: "THA",
    COUNTRY_NUMERIC_CODE: 764,
    COUNTRY_NAME: "Thailand",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TJ",
    COUNTRY_ALPHA3_CODE: "TJK",
    COUNTRY_NUMERIC_CODE: 762,
    COUNTRY_NAME: "Tajikistan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TK",
    COUNTRY_ALPHA3_CODE: "TKL",
    COUNTRY_NUMERIC_CODE: 772,
    COUNTRY_NAME: "Tokelau",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TL",
    COUNTRY_ALPHA3_CODE: "TLS",
    COUNTRY_NUMERIC_CODE: 626,
    COUNTRY_NAME: "Timor-Leste",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TM",
    COUNTRY_ALPHA3_CODE: "TKM",
    COUNTRY_NUMERIC_CODE: 795,
    COUNTRY_NAME: "Turkmenistan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TN",
    COUNTRY_ALPHA3_CODE: "TUN",
    COUNTRY_NUMERIC_CODE: 788,
    COUNTRY_NAME: "Tunisia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TO",
    COUNTRY_ALPHA3_CODE: "TON",
    COUNTRY_NUMERIC_CODE: 776,
    COUNTRY_NAME: "Tonga",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TR",
    COUNTRY_ALPHA3_CODE: "TUR",
    COUNTRY_NUMERIC_CODE: 792,
    COUNTRY_NAME: "Turkey",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TT",
    COUNTRY_ALPHA3_CODE: "TTO",
    COUNTRY_NUMERIC_CODE: 780,
    COUNTRY_NAME: "Trinidad and Tobago",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TV",
    COUNTRY_ALPHA3_CODE: "TUV",
    COUNTRY_NUMERIC_CODE: 798,
    COUNTRY_NAME: "Tuvalu",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TW",
    COUNTRY_ALPHA3_CODE: "TWN",
    COUNTRY_NUMERIC_CODE: 158,
    COUNTRY_NAME: "Taiwan (Province of China)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "TZ",
    COUNTRY_ALPHA3_CODE: "TZA",
    COUNTRY_NUMERIC_CODE: 834,
    COUNTRY_NAME: "Tanzania, United Republic of",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "UA",
    COUNTRY_ALPHA3_CODE: "UKR",
    COUNTRY_NUMERIC_CODE: 804,
    COUNTRY_NAME: "Ukraine",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "UG",
    COUNTRY_ALPHA3_CODE: "UGA",
    COUNTRY_NUMERIC_CODE: 800,
    COUNTRY_NAME: "Uganda",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "UM",
    COUNTRY_ALPHA3_CODE: "UMI",
    COUNTRY_NUMERIC_CODE: 581,
    COUNTRY_NAME: "United States Minor Outlying Islands",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "US",
    COUNTRY_ALPHA3_CODE: "USA",
    COUNTRY_NUMERIC_CODE: 840,
    COUNTRY_NAME: "United States of America",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "UY",
    COUNTRY_ALPHA3_CODE: "URY",
    COUNTRY_NUMERIC_CODE: 858,
    COUNTRY_NAME: "Uruguay",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "UZ",
    COUNTRY_ALPHA3_CODE: "UZB",
    COUNTRY_NUMERIC_CODE: 860,
    COUNTRY_NAME: "Uzbekistan",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VA",
    COUNTRY_ALPHA3_CODE: "VAT",
    COUNTRY_NUMERIC_CODE: 336,
    COUNTRY_NAME: "Holy See",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VC",
    COUNTRY_ALPHA3_CODE: "VCT",
    COUNTRY_NUMERIC_CODE: 670,
    COUNTRY_NAME: "Saint Vincent and the Grenadines",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VE",
    COUNTRY_ALPHA3_CODE: "VEN",
    COUNTRY_NUMERIC_CODE: 862,
    COUNTRY_NAME: "Venezuela (Bolivarian Republic of)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VG",
    COUNTRY_ALPHA3_CODE: "VGB",
    COUNTRY_NUMERIC_CODE: 92,
    COUNTRY_NAME: "Virgin Islands (British)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VI",
    COUNTRY_ALPHA3_CODE: "VIR",
    COUNTRY_NUMERIC_CODE: 850,
    COUNTRY_NAME: "Virgin Islands (U.S.)",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VN",
    COUNTRY_ALPHA3_CODE: "VNM",
    COUNTRY_NUMERIC_CODE: 704,
    COUNTRY_NAME: "Viet Nam",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "VU",
    COUNTRY_ALPHA3_CODE: "VUT",
    COUNTRY_NUMERIC_CODE: 548,
    COUNTRY_NAME: "Vanuatu",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "WF",
    COUNTRY_ALPHA3_CODE: "WLF",
    COUNTRY_NUMERIC_CODE: 876,
    COUNTRY_NAME: "Wallis and Futuna",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "WS",
    COUNTRY_ALPHA3_CODE: "WSM",
    COUNTRY_NUMERIC_CODE: 882,
    COUNTRY_NAME: "Samoa",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "YE",
    COUNTRY_ALPHA3_CODE: "YEM",
    COUNTRY_NUMERIC_CODE: 887,
    COUNTRY_NAME: "Yemen",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "YT",
    COUNTRY_ALPHA3_CODE: "MYT",
    COUNTRY_NUMERIC_CODE: 175,
    COUNTRY_NAME: "Mayotte",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ZA",
    COUNTRY_ALPHA3_CODE: "ZAF",
    COUNTRY_NUMERIC_CODE: 710,
    COUNTRY_NAME: "South Africa",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ZM",
    COUNTRY_ALPHA3_CODE: "ZMB",
    COUNTRY_NUMERIC_CODE: 894,
    COUNTRY_NAME: "Zambia",
  },
  {
    LANG: "EN",
    LANG_NAME: "ENGLISH",
    COUNTRY_ALPHA2_CODE: "ZW",
    COUNTRY_ALPHA3_CODE: "ZWE",
    COUNTRY_NUMERIC_CODE: 716,
    COUNTRY_NAME: "Zimbabwe",
  },
];
export const paidSections = [
  "projects",
  "volunteering",
  "onlinePresence",
  "certifications",
  "references",
  "publications",
  "books",
  "additionalExperience",
  "additionalSkills",
  "courses",
  "industryExpertise",
  "techStack",
  "strengths",
  "customDateSection",
  "customTextSection",
];
export const customField = {
  customTitle: "",
  data: [
    {
      custom_title: "",
      display_name: "",
      icon: "",
    },
  ],
};

export const experience = {
  show: true,
  customTitle: "Work Experience",
  timelineWidth: 136,
  data: [
    {
      position: "",
      company: null,
      startFrom: "Jan/2018",
      endUntil: "Present",
      website: null,
      location: null,
      description: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      showFields: {
        position: true,
        company: true,
        date: true,
        location: true,
        website: true,
        description: true,
      },
    },
  ],
};

export const skills = {
  show: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "",
      level: 50,
      description: null,
      showFields: { name: true, slider: true, description: true },
    },
  ],
};

export const summary = {
  show: true,
  customTitle: "",
  data: [
    {
      description: null,
    },
  ],
};

export const education = {
  show: true,
  customTitle: "Education",
  data: [
    {
      degree: null,
      school: null,
      startFrom: null,
      endUntil: null,
      website: null,
      location: null,
      gpa: "",
      gpaText: "",
      maxGpa: "",
      description: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      showFields: {
        degree: true,
        school: true,
        date: true,
        location: true,
        website: true,
        description: true,
      },
    },
  ],
};

export const achievements = {
  show: true,
  customTitle: null,
  data: [
    {
      name: null,
      description: null,
      icon: "",
      showFields: { name: true, icon: true, description: true },
    },
  ],
};

export const interests = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      level: null,
      icon: "129-free-heart",
      description: "",
      showFields: { name: true, level: true, icon: true, description: true },
    },
  ],
};

export const strengths = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "129-free-heart",
      showFields: { name: true, icon: true },
    },
  ],
};

export const languages = {
  show: true,
  customTitle: null,
  data: [
    {
      name: null,
      level: "Select Your Level",
      levelText: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
        proficiencyText: true,
      },
    },
  ],
};

export const customDate = {
  show: true,
  customTitle: "",
  data: [
    {
      title: "",
      startFrom: "",
      endUntil: "",
      description: "",
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      showFields: {
        date: true,
        description: true,
        icon: true,
      },
    },
  ],
};

export const customText = {
  show: true,
  customTitle: "",
  data: [
    {
      title: "",
      description: "",
      showFields: {
        description: true,
      },
    },
  ],
};

// export const references = {
//   show: true,
//   customTitle: "",
//   data: [
//     {
//       name: "",
//       company: "",
//       phone: "",
//       email: "",
//     },
//   ],
// };

export const social = {
  show: true,
  customTitle: "",
  data: [
    {
      label: "",
      link: "",
    },
  ],
};

export const style = {
  fontSize: 2,
  titleFont: "Montserrat",
  bodyFont: "Montserrat",
  linkColor: "#616265",
  primaryColor: "#1F1F1F",
  secondaryColor: "#701233",
  descriptionColor: "#1F1F1F",
  titleBgColor: "#efe9e0",
  initialLoad: true,
  grid_width: 260,
  resumeLayout: "single",
  SectionMargin: "2",
  PageMargin: "2",
  bgColor: {
    bgColor: "#ffffff",
    bgFill: "full",
    varients: [],
  },
  multi_color_option: [
    {
      primary_color: "#e52165",
      secondary_color: "#0d1137",
    },
    {
      primary_color: "#d72631",
      secondary_color: "#a2d5c6",
    },
    {
      primary_color: "#077b8a",
      secondary_color: "#5c3c92",
    },
    {
      primary_color: "#e2d810",
      secondary_color: "#d9138a",
    },
  ],
  background: {
    bgShow: false,
    bgImage: "bg3.svg",
  },
};

export const layout = [
  [
    {
      page: 0,
      height: 379,
      section: "profile",
      itemIndexes: [0],
    },
    {
      page: 0,
      height: 98,
      section: "summary",
      itemIndexes: [0],
    },
    {
      page: 0,
      height: 192,
      section: "experience",
      itemIndexes: [0, 1],
    },
    {
      page: 0,
      height: 136,
      section: "education",
      itemIndexes: [0, 1],
    },
    {
      page: 0,
      height: 120,
      section: "skills",
      itemIndexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      page: 0,
      height: 99,
      section: "achievements",
      itemIndexes: [0, 1, 2, 3],
    },
  ],
  [],
];
export const settings = {
  showIcon: true,
  sectionHeaderUnderline: false,
  isTitleBackgroundColor: true,
  skills: {
    sliderType: 2,
    headerStyle: "Header1",
    sliders: ["dots", "range"],
    skillColor: {
      c_forty: "#f44336",
      c_sixty: "#808080",
      c_eighty: "#2196F3",
      c_twenty: "#f44336",
      c_hundred: "#04AA6D",
    },
  },
  profile: {
    photoStyle: "square",
    headerStyle: "Word1",
    profileSize: "medium",
  },
  education: {
    headerStyle: "Header3",
  },
  interests: {
    headerStyle: "Header1",
  },
  languages: {
    levelTexts: {
      low: "Beginer",
      good: "Beginer",
      expert: "Beginer",
      veryLow: "Beginer",
      intermediate: "Beginer",
    },
    headerStyle: "Header1",
    indicatorType: "dots",
  },
  experience: {
    headerStyle: "Header2",
  },
  achievements: {
    headerStyle: "Header1",
  },
  additionalSkills: {
    border: true,
  },
};
export const defaultResumeData = {
  profile: profile,
  summary: summary,
  experience: experience,
  education: education,
  skills: skills,
  achievements: achievements,
  languages: languages,
  interests: interests,
  //   customDate: customDate,
  //   customText: customText,
  // references: references,
  social: social,
  sectionPosition: {
    page: 0,
    lsbar: [
      "profile",
      "summary",
      "experience",
      "education",
      "skills",
      "achievements",
    ],
    rsbar: [],
    restrictions: {
      lsbar: ["experience"],
      rsbar: ["languages"],
    },
  },
  layout: layout,
  editorSettings: settings,
  customization: style,
  sectionTitles: {},
};

const masterData = [];

export const from = {
  name: "",
  jobTitle: "",
  address: "",
  email: "",
  phone: "",
  city: "",
  website: "",
};

export const to = {
  hr_name: "",
  companyName: "",
  companyAddress: "",
  city: "",
  date: "",
};

export const content = {
  description: "<p><br></p>",
};

export const coverSettings = {
  showIcon: false,
  from: {
    headerStyle: "Header1",
    photoStyle: "square",
    profileSize: "large",
    defaultHeader: "Header1",
    isdefaultHeader: true,
    showdefaultHeader: true,
    isBorder: true,
  },
  to: {
    headerStyle: "Header1",
  },
  content: {
    headerStyle: "Header1",
  },
};

export const coverStyle = {
  colors: {
    primaryColor: "#27496d",
    secondaryColor: "#00909e",
    sectionTitleColor: "#cd7e31",
    descriptionColor: "#656665",
    bgColor: "#ffffff",
  },
  background: {
    bgShow: false,
    bgImage: "c-bg1.png",
  },
  fonts: {
    titleFont: "Roboto",
    bodyFont: "Roboto",
    titleFontSize: "20px",
    bodyFontSize: "16px",
    bold: "700",
    medium: "500",
    regular: "400",
    multi_color_option: [
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
      {
        primary_color: "#27496d",
        secondary_color: "#00909e",
      },
    ],
  },
  SectionMargin: "12px",
  PageMargin: "30px",
};

export const defaultCoverData = {
  from: from,
  to: to,
  content: content,
  editorSettings: coverSettings,
  customization: coverStyle,
};

export const availableSection = [
  {
    sectionId: "summary",
    image: "/images/Language.svg",
    font_class: "fa fa-diamond",
  },
  {
    sectionId: "experience",
    image: "/images/Internship.svg",
    font_class: "fa fa-briefcase",
  },
  {
    sectionId: "education",
    image: "/images/education.svg",
    font_class: "fa fa-graduation-cap",
  },
  {
    sectionId: "skills",
    image: "/images/skill.svg",
    font_class: "fa fa-shield",
  },
  {
    sectionId: "languages",
    image: "/images/Language.svg",
    font_class: "fa fa-language",
  },
  {
    sectionId: "achievements",
    image: "/images/Hobbies.svg",
    font_class: "fa fa-trophy",
  },
  {
    sectionId: "interests",
    image: "/images/interest.svg",
    font_class: "fa fa-th",
  },
  {
    sectionId: "projects",
    image: "/images/interest.svg",
    font_class: "fa fa-file-powerpoint-o",
  },
  {
    sectionId: "volunteering",
    image: "/images/interest.svg",
    font_class: "fa fa-users",
  },
  {
    sectionId: "onlinePresence",
    image: "/images/interest.svg",
    font_class: "fa fa-share-square-o",
  },
  {
    sectionId: "certifications",
    image: "/images/interest.svg",
    font_class: "fa fa-certificate",
  },
  {
    sectionId: "references",
    image: "/images/interest.svg",
    font_class: "fa fa-user",
  },
  {
    sectionId: "publications",
    image: "/images/interest.svg",
    font_class: "fa fa-language",
  },
  {
    sectionId: "books",
    image: "/images/interest.svg",
    font_class: "fa fa-book",
  },
  {
    sectionId: "additionalExperience",
    image: "/images/interest.svg",
    font_class: "fa fa-briefcase",
  },
  {
    sectionId: "additionalSkills",
    image: "/images/interest.svg",
    font_class: "fa fa-shield",
  },
  {
    sectionId: "courses",
    image: "/images/interest.svg",
    font_class: "fa fa-book",
  },
  {
    sectionId: "industryExpertise",
    image: "/images/interest.svg",
    font_class: "fa fa-briefcase",
  },
  {
    sectionId: "strengths",
    image: "/images/interest.svg",
    font_class: "fa fa-briefcase",
  },
  //   {
  //     sectionId: "customDate",
  //     image: "/images/interest.svg",
  //   },
  //   {
  //     sectionId: "customText",
  //     image: "/images/interest.svg",
  //   },
];

export const backgroundImage = [
  "bg1.png",
  "bg2.svg",
  "bg3.svg",
  "bg4.svg",
  "bg5.png",
  "bg6.png",
  "bg7.png",
  "bg8.png",
  "bg9.png",
  "bg10.png",
  // "bg11.png",
  "bg12.png",
  "bg13.png",
  "bg14.png",
  "bg15.png",
  "bg16.png",
];

export const coverBackgroundImage = [
  "c-bg1.png",
  "c-bg2.png",
  "c-bg3.png",
  "c-bg4.png",
];

export const sampleSuggestion = [
  {
    description:
      "Worked to ensure a positive and hassle-free customer experience.",
  },
  {
    description:
      "Settled any customer disputes in a professional and pleasant manner.",
  },
  {
    description:
      "Identified and maximized sales opportunities, and increased customer retention rates.",
  },
  {
    description: "Handled food with sanitation and safety in mind.",
  },
  {
    description:
      "Worked to ensure a neat and attractive sales environment, and assisted in the setup of visual displays.",
  },
  {
    description:
      "Helped to increase customer return rates by providing excellent customer service at all times.",
  },
  {
    description:
      "Brought forth excellent customer service skills and a committment to customer satisfaction.",
  },
];

export const projects = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      location: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      icon: "129-free-heart",
      description: "",

      showFields: {
        name: true,
        location: true,
        icon: true,
        description: true,
        date: true,
      },
    },
  ],
};

export const volunteering = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      organization: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      icon: "129-free-heart",
      description: "",

      showFields: {
        name: true,
        organization: true,
        icon: true,
        description: true,
        date: true,
      },
    },
  ],
};
export const onlinePresence = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      socialNetwork: null,
      userName: null,
      icon: "129-free-heart",

      showFields: {
        socialNetwork: true,
        userName: true,
        icon: true,
      },
    },
  ],
};
export const certifications = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "129-free-heart",
      description: "",
      showFields: {
        name: true,
        icon: true,
        description: true,
      },
    },
  ],
};
export const references = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      referenceName: null,
      referenceContact: null,
      icon: "129-free-heart",

      showFields: {
        referenceName: true,
        referenceContact: true,
        icon: true,
      },
    },
  ],
};
export const publications = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      publishingCompany: null,
      authors: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      icon: "129-free-heart",
      description: "",

      showFields: {
        name: true,
        publishingCompany: true,
        authors: true,
        icon: true,
        description: true,
        date: true,
      },
    },
  ],
};
export const books = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      title: null,
      authors: null,
      icon: "129-free-heart",
      image: null,

      showFields: {
        title: true,
        authors: true,
        icon: true,
        image: true,
      },
    },
  ],
};
export const additionalExperience = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      company: null,
      location: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      icon: "129-free-heart",
      description: "",

      showFields: {
        name: true,
        company: true,
        location: true,
        icon: true,
        description: true,
        date: true,
      },
    },
  ],
};
export const additionalSkills = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "129-free-heart",

      showFields: {
        name: true,
        icon: true,
      },
    },
  ],
};
export const courses = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "129-free-heart",

      showFields: {
        name: true,
        icon: true,
      },
    },
  ],
};
export const industryExpertise = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      icon: "",
      level: 50,
      description: null,
      showFields: { name: true, slider: true, description: true },
    },
  ],
};
export const techStack = {
  show: true,
  showIcons: true,
  customTitle: null,
  data: [
    {
      name: null,
      location: null,
      dateRange: {
        fromYear: null,
        fromMonth: null,
        toYear: null,
        toMonth: null,
        isOnGoing: false,
        onGoingText: "Ongoing",
      },
      icon: "129-free-heart",
      description: "",

      showFields: {
        name: true,
        location: true,
        icon: true,
        description: true,
        date: true,
      },
    },
  ],
};
export const sampleData = {
  summary:
    "Over 4 years of experience as Assistant Behavior Analyst in the Educational domain with expertise in providing quality Behavioural services to students and staff training based on current practices in Applied Behavior Analysis.",
  experience: [
    {
      position: "Behavior Analyst - Education",
      company: "Hogan Learning Academy",
      startFrom: "Nov 2015",
      endUntil: "Present",
      website: "Hogan Learning Academy",
      location: "Fleetwood, PA",
      description:
        "<ul>\n<li>Execute and follow the ethical standards for behavior analysts as outlined through the BACB.</li>\n<li>Maintain client confidentiality through the standards set through FERPA / HIPAA privacy rule and the BACB.</li>\n<li>Conduct functional behavior assessment (FBA) for each assigned student and develop behavioral goals for each assigned students' IEP and behavior support plan.</li>\n<li>Develop lesson plans and mastery criteria for students' behavioral goals.</li>\n<li>Develop specific, individualized systems of data collection for each assigned student.</li>\n<li>Execution of data collection methods to support interventions and behavior change.</li>\n<li>Revise and update behavior support plans and behavior goals as data suggest the need for intervention change.</li>\n<li>Oversee staff implementation of behavior support plans and behavior goals.</li>\n</ol>",
      showFields: {
        position: true,
        company: true,
        date: true,
        location: true,
        website: true,
        description: true,
      },
      dateRange: {
        fromYear: 2019,
        fromMonth: "Jan",
        toYear: 2022,
        toMonth: "Jan",
        isOnGoing: true,
        onGoingText: "",
      },
    },
    {
      position: "Behavior Analyst",
      company: "ABC Corp",
      startFrom: "May 2013",
      endUntil: "Nov 2015",
      website: "ABC Corp",
      location: "Austin, TX",
      description:
        '<ul>\n<li class="content">Behavior Analysts are responsible for conducting functional assessments, preference assessments, developing behavior analysis service plans, implementing acquisition and replacement programs, collecting data for target behaviors, and graph data accordingly on monthly progress reports; training and monitoring behavior assistants in the implementation and data collection of target behaviors.</li>\n<li class="content">Behavior analysts are responsible for training and monitoring parents and caregivers in the implementation of the program.</li>\n<li class="content">\n<div class="content">Implemented behavioral programs and established a rapport with the clients.</div>\n</li>\n<li class="content">\n<div class="content">Applied behavior implementation techniques to autistic children and provided in-home-supportive services to families.</div>\n</li>\n</ol>',
      showFields: {
        position: true,
        company: true,
        date: true,
        location: true,
        website: true,
        description: true,
      },
      dateRange: {
        fromYear: 2019,
        fromMonth: "Jan",
        toYear: 2022,
        toMonth: "Jan",
        isOnGoing: true,
        onGoingText: "",
      },
    },
  ],

  education: [
    {
      degree: "\nBachelor Of Arts In Applied Psychology ",
      school: "The Pennsylvania State University",
      startFrom: "Mar 2011",
      endUntil: "Dec 2015",
      website: null,
      location: null,
      gpa: "",
      gpaText: "",
      maxGpa: "",
      description: "",
      showFields: {
        degree: true,
        school: true,
        date: true,
        location: true,
        website: true,
        description: true,
      },
      dateRange: {
        fromYear: 2019,
        fromMonth: "Jan",
        toYear: 2022,
        toMonth: "Jan",
        isOnGoing: true,
        onGoingText: "",
      },
    },
  ],
  skills: [
    {
      name: "Behavioral Assessments",
      icon: "",
      level: 50,
      description: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
      },
    },
    {
      name: " Adaptive Assessments",
      icon: "",
      level: 50,
      description: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
      },
    },
    {
      name: " Data Collection",
      icon: "",
      level: 50,
      description: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
      },
    },
    {
      name: " Staff Training",
      icon: "",
      level: 50,
      description: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
      },
    },
    {
      name: " Client confidentiality standards set by FERPA / HIPPA Privacy Rules.",
      icon: "",
      level: 50,
      description: "",
      showFields: {
        name: true,
        slider: true,
        description: true,
      },
    },
  ],
};
export const pageMargins = [30, 40, 50, 60];
export const sectionPaddings = [25, 30, 40, 50];
export const inbetweenSectionPaddings = [15, 15, 18, 20];
export const fontVariations = [
  {
    h1: "30px",
    h1_lineHeight: "36px",
    h2: "16px",
    h2_lineHeight: "20px",
    h3: "15px",
    h3_lineHeight: "19px",
    h4: "14px",
    h4_lineHeight: "18px",
    p1: "13px",
    p1_lineHeight: "17px",
    p2: "11px",
    p2_lineHeight: "13px",
    tool: "14px",
    tool_lineHeight: "18px",
  },
  {
    h1: "34px",
    h1_lineHeight: "40px",
    h2: "18px",
    h2_lineHeight: "22px",
    h3: "16px",
    h3_lineHeight: "20px",
    h4: "14px",
    h4_lineHeight: "18px",
    p1: "14px",
    p1_lineHeight: "18px",
    p2: "11px",
    p2_lineHeight: "13px",
    tool: "14px",
    tool_lineHeight: "18px",
  },
  {
    h1: "38px",
    h1_lineHeight: "42px",
    h2: "20px",
    h2_lineHeight: "24px",
    h3: "18px",
    h3_lineHeight: "22px",
    h4: "15px",
    h4_lineHeight: "19px",
    p1: "15px",
    p1_lineHeight: "19px",
    p2: "12px",
    p2_lineHeight: "13px",
    tool: "15px",
    tool_lineHeight: "19px",
  },
  {
    h1: "42px",
    h1_lineHeight: "48px",
    h2: "24px",
    h2_lineHeight: "28px",
    h3: "20px",
    h3_lineHeight: "24px",
    h4: "15px",
    h4_lineHeight: "19px",
    p1: "15px",
    p1_lineHeight: "19px",
    p2: "12px",
    p2_lineHeight: "13px",
    tool: "16px",
    tool_lineHeight: "20px",
  },
];

export const profileSectionMargin = ["4px", "6px", "8px", "16px", "16px"];
export const otherSectionMargin = ["8px", "12px", "14px", "16px", "18px"];
export const sectionPadding = [
  "4px 10px",
  "6px 12px",
  "8px 16px",
  "12px 16px",
  "16px 24px",
];
export const sectionPaddingMargin = [
  "4px 10px 0px",
  "6px 12px 0px",
  "8px 16px 0px",
  "12px 16px 0px",
  "16px 24px 0px",
];
export const skillSliderTypes = [1, 2, 3];
export const profileImageTypes = ["round", "square"];
export const newFonts = {
  common: [
    {
      sectionHeader: "16px",
      sectionHeaderLineHeight: "19px",
      fontLargeSize: "14px",
      fontLargeLineHeight: "15px",
      fontMediumSize: "13px",
      fontMediumLineHeight: "15px",
      fontSmallSize: "12px",
      fontSmallLineHeight: "15px",
      description: "12px",
    },
    {
      sectionHeader: "18px",
      sectionHeaderLineHeight: "21px",
      fontLargeSize: "16px",
      fontLargeLineHeight: "19px",
      fontMediumSize: "14px",
      fontMediumLineHeight: "17px",
      fontSmallSize: "12px",
      fontSmallLineHeight: "15px",
      description: "12px",
    },
    {
      sectionHeader: "22px",
      sectionHeaderLineHeight: "26px",
      fontLargeSize: "17px",
      fontLargeLineHeight: "20px",
      fontMediumSize: "15px",
      fontMediumLineHeight: "18px",
      fontSmallSize: "13px",
      fontSmallLineHeight: "16px",
      description: "12px",
    },
    {
      // sectionHeader: "28px",
      sectionHeader: "24px",
      sectionHeaderLineHeight: "33px",
      fontLargeSize: "19px",
      fontLargeLineHeight: "20px",
      fontMediumSize: "15px",
      fontMediumLineHeight: "18px",
      fontSmallSize: "13px",
      fontSmallLineHeight: "16px",
      description: "13px",
    },
  ],
  profile: [
    {
      name: "30px",
      nameLineHeight: "36px",
      jobTitle: "15px",
      jobLineheight: "18px",
      profileInfo: "12px",
      profileInfoLineHeight: "15px",
    },

    {
      name: "34px",
      nameLineHeight: "1",
      jobTitle: "17px",
      jobLineheight: "20px",
      profileInfo: "12px",
      profileInfoLineHeight: "15px",
    },
    {
      name: "38px",
      nameLineHeight: "1",
      jobTitle: "19px",
      jobLineheight: "23px",
      profileInfo: "12px",
      profileInfoLineHeight: "15px",
    },
    {
      name: "42px",
      nameLineHeight: "1",
      jobTitle: "24px",
      jobLineheight: "25px",
      profileInfo: "12px",
      profileInfoLineHeight: "15px",
    },
  ],
  summary: [
    {
      description: "12px",
      lineHeight: "16px",
    },
    {
      description: "13px",
      lineHeight: "17px",
    },
    {
      description: "14px",
      lineHeight: "18px",
    },
    {
      // description: "15px",
      description: "14px",
      lineHeight: "21px",
    },
  ],
  experience: [
    {
      position: "14px",
      positionLineHeight: "17px",
      company: "13px",
      companyLineHeight: "16px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
      url: "11px",
      urlLineHeight: "13px",
    },
    {
      position: "16px",
      positionLineHeight: "19px",
      company: "13px",
      companyLineHeight: "16px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
      url: "11px",
      urlLineHeight: "13px",
    },
    {
      position: "18px",
      positionLineHeight: "21px",
      company: "14px",
      companyLineHeight: "17px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
      url: "11px",
      urlLineHeight: "13px",
    },
    {
      position: "21px",
      positionLineHeight: "25px",
      company: "15px",
      companyLineHeight: "18px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "13px",
      bulletsLineHeight: "16px",
      description: "13px",
      descriptionLineHeight: "16px",
      url: "11px",
      urlLineHeight: "13px",
    },
  ],
  education: [
    {
      degree: "14px",
      degreeLineHeight: "17px",
      school: "13px",
      schoolLineHeight: "16px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
    },
    {
      degree: "16px",
      degreeLineHeight: "19px",
      school: "13px",
      schoolLineHeight: "16px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
    },
    {
      degree: "18px",
      degreeLineHeight: "21px",
      school: "14px",
      schoolLineHeight: "17px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "12px",
      bulletsLineHeight: "15px",
      description: "12px",
      descriptionLineHeight: "15px",
    },
    {
      degree: "21px",
      degreeLineHeight: "25px",
      school: "15px",
      schoolLineHeight: "18px",
      date: "11px",
      dateLineHeight: "13px",
      location: "11px",
      locationLineHeight: "13px",
      bullets: "13px",
      bulletsLineHeight: "16px",
      description: "13px",
      descriptionLineHeight: "16px",
    },
  ],
};

export default masterData;
